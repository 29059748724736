const CheckIcon = ({ width }) => {
  return (
    <>
      {width === 16 && (
        <svg
          width="16"
          height="16"
          viewBox={`0 0 16 16`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_617)">
            <rect width="16" height="16" rx="8" fill="#F16D78" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 5.98715L7.56693 11H6.46206L4 8.21594L5.10486 7.22879L7.01449 9.38817L10.8951 5L12 5.98715Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_617">
              <rect width="16" height="16" rx="8" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      {width === 12 && (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_1984)">
            <rect width="12" height="12" rx="6" fill="#F16D78" />
            <path
              fillRule="evenodd"
              clip-rule="evenodd"
              d="M8.99878 4.49049L5.67397 8.25012H4.84533L2.99878 6.16208L3.82743 5.42171L5.25965 7.04125L8.17013 3.75012L8.99878 4.49049Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_1984">
              <rect width="12" height="12" rx="6" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default CheckIcon;
