import { useContext, useState } from "react";

import Button from "../Button/Button";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";

export default function GoogleButton({ className }) {
  const { socialAuthLogin } = useAuth({
    middleware: "guest",
  });
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const [errors, setErrors] = useState([]);
  const googleLogin = async () => {
    let type = "google";
    let resp = await socialAuthLogin({ setErrors, type });
    if (resp.status == 200) {
      window.location.href = resp.data;
    } else if (resp.status == 422) {
      setErrors(resp.errors);
    }
  };
  return (
    <div>
      <Button
        type="button"
        className={`border border-gray-200 rounded text-base ${className}`}
        onClick={() => googleLogin()}
      >
        <span className="pr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.0008 12.2635C24.0008 11.2733 23.9174 10.5557 23.7414 9.81079H12.251V14.262H18.9969C18.8579 15.3702 18.1259 17.0326 16.495 18.159L16.4765 18.3044L20.1089 21.0659L20.3591 21.0932C22.6665 19.0038 24.0008 15.9243 24.0008 12.2635Z"
              fill="#4285F4"
            />
            <path
              d="M12.2505 23.9999C15.5587 23.9999 18.3293 22.9371 20.3587 21.093L16.4946 18.1589C15.4567 18.8674 14.076 19.358 12.2505 19.358C9.01656 19.358 6.26443 17.2687 5.28219 14.3708L5.14319 14.3799L1.36248 17.2505L1.30688 17.3867C3.32697 21.311 7.4598 23.9999 12.2505 23.9999Z"
              fill="#34A853"
            />
            <path
              d="M5.28187 14.3709C5.02241 13.626 4.87415 12.8266 4.87415 12C4.87415 11.1733 5.02241 10.3739 5.2726 9.62905L5.26334 9.46553L1.4363 6.54956L1.30657 6.60407C0.472588 8.23011 0 10.056 0 11.9909C0 13.9258 0.472588 15.7517 1.30657 17.3777L5.28187 14.3709Z"
              fill="#FBBC05"
            />
            <path
              d="M12.2493 4.64194C14.5474 4.64194 16.0949 5.61393 16.9845 6.43149L20.4409 3.12491C18.3189 1.19001 15.5575 0 12.2493 0C7.45859 0 3.32576 2.69796 1.31494 6.61317L5.27171 9.62907C6.26322 6.73127 9.00609 4.64194 12.2493 4.64194Z"
              fill="#EB4335"
            />
          </svg>{" "}
        </span>
        {t.continueWithGoogle}
      </Button>
    </div>
  );
}
