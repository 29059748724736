import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";
import { Frames, CardFrame } from "frames-react";
import { useState, useEffect, useContext } from "react";
import withReactContent from "sweetalert2-react-content";

import Text from "../../Text/Text";
import Input from "../../Input/Input";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";


export default function AddCardForm({ setShowModal }) {
  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');

  const { updateUserBilling, getUser } = useAuth({
    middleware: "auth",
  });
  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const user = usercontext.user;

  const t = appcontext.t;
  const locale = appcontext.locale;
  const MySwal = withReactContent(Swal);

  const [errors, setErrors] = useState([]);
  const [feErrors, seteErrors] = useState([]);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(null);

  const handleToastClose = () => {
    setOpen(false);
  };

  const submitForm = async (e) => {
    if (e) {
      setLoader(true);
      const resp = await updateUserBilling({
        token: e.token,
        setErrors,
        setStatus,
      });
      setLoader(false);
      if (resp?._links) {
        window.location.href = resp?._links?.redirect?.href;
      }
      if (resp?.error_codes) {
        setErrors(resp?.error_codes);
      }
    }
  };

  useEffect(() => {
    if (status) {
      MySwal.fire({
        icon: "success",
        title: "Your card added successfully",
        confirmButtonText: "Ok",
      }).then(async (result) => {
        if (result.isConfirmed) {
          getUser({ setErrors });
          setShowModal(false);
          location.reload();
        }
      });
    }
  }, [status]);

  return (
    <>
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
      <div className="w-full sm:w-[350px] md:w-[400px] lg:w-[450px] xl:w-[550px] pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[20px] relative pb-6 mx-auto">
        <div className="text-center">
          <Text as="h2" className="text-lg mt-4 mb-[70px] font-semibold">
            {user?.billing ? <>{t.changeCard}</> : <>{t.addNewCard}</>}
          </Text>
        </div>
        {/* Validation Errors */}
        <AuthValidationErrors className="mb-4" errors={errors} t={t} />
        <div className={`mb-4 mx-2 ${locale == "ar" ? "text-right" : ""}`}>
          <label>
            {t.cardHolderName}
            <span className="text-grey200 ml-1">(Optional)</span>
          </label>
          <Input
            placeholder="i.e. John Doe"
            className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent font-medium  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full text-black ${locale == "ar" ? "text-right rtl-input" : ""
              }`}
          />
          {feErrors.cardName && (
            <p
              className={`text-red text-sm ${locale == "ar" ? "text-right rtl-input" : ""
                }`}
            >
              {t.cardNameRequired}
            </p>
          )}
        </div>
        <Frames
          config={{
            publicKey: process.env.NEXT_PUBLIC_CHECKOUT_KEY,
            style: {
              base: {
                fontSize: "16px",
                color: "black",
                border: "1px solid #D1D2D4",
                padding: "0px 10px",
                borderRadius: "10px",
                minWidth: "80px",
                height: "50px",
              },
              placeholder: {
                base: {
                  color: "#D1D2D4",
                },
              },
            },
          }}
          cardTokenized={(e) => {
            submitForm(e);
          }}
        >
          <CardFrame />
          <div className="mx-2">
            <button
              className="btn btn-green btn-block rounded-full font-bold"
              onClick={() => {
                Frames.submitCard();
                if (!Frames.isCardValid()) {
                  setOpen(true);
                  seToastMsg('Invalid card details');
                }
                console.log("frame submitted");
              }}
            >
              {t.startMyFreeTrial}

              {loader && (
                <div className="ml-4">
                  <Oval height="22" width="22" color="white" />
                </div>
              )}
            </button>
          </div>
        </Frames>
      </div>
    </>
  );
}
