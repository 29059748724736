import Image from "next/image";
import loader from "@/assets/images/loader.svg";
import styles from "./Loader.module.scss";

const Loader = ({ className = "" }) => {
  return (
    <div className={`${className} ${styles.loader}`}>
      <Image src={loader} width={100} height={100} />
    </div>
  );
};

export default Loader;
