
import Snackbar from '@mui/material/Snackbar';
import { useState, useEffect, useContext } from "react";

import AppContext from "@/context/AppContext";

export default function InviteTeamMember({ open, toastMsg, handleClose }) {
    const appcontext = useContext(AppContext);
    const t = appcontext.t;
    const locale = appcontext.locale;
    const isArabic = locale === "ar";

    const vertical = 'top';
    const horizontal = isArabic ? 'right' : 'left';

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
            message={toastMsg}
            key={vertical + horizontal}
        />
    );
}

