const MailBox = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="48.5" stroke="#101820" strokeWidth="3" />
      <path
        d="M70 50H58L54 56.6667H46L42 50H30"
        stroke="#101820"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9 37.0813L30 50V61.25C30 62.2446 30.4214 63.1984 31.1716 63.9016C31.9217 64.6049 32.9391 65 34 65H66C67.0609 65 68.0783 64.6049 68.8284 63.9016C69.5786 63.1984 70 62.2446 70 61.25V50L63.1 37.0813C62.7688 36.4565 62.2584 35.9307 61.6259 35.563C60.9935 35.1954 60.2642 35.0004 59.52 35H40.48C39.7358 35.0004 39.0065 35.1954 38.3741 35.563C37.7417 35.9307 37.2312 36.4565 36.9 37.0813V37.0813Z"
        stroke="#101820"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailBox;
