import { useState, useEffect, useContext } from "react";

import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";

export default function Hyperpay({ setStep }) {
    const { getHyperpayCheckout } = useAuth({
      middleware: "auth",
    });

    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);

    const getHyperpayCheckoutId = async () => {
        const hyperpay = await getHyperpayCheckout();
        appcontext.setHyperpayCheckout(hyperpay?.id);
    }
    useEffect(() => {
        if (appcontext.paymentGateway) {
          getHyperpayCheckoutId();
        }
    }, [appcontext.paymentGateway]);
    var wpwlOptions = {
      paymentTarget:"_top",
    }
   
    return (
      <>
        <>
          {appcontext.hyperpayCheckout && (
            <form className="wpwl-form wpwl-clearfix paymentWidgets" action={process.env.NEXT_PUBLIC_FRONTEND_URL + '/success'} data-brands="MADA VISA MASTER AMEX"></form>
          )}  
        </>
      </>
    )
};