const AuthValidationErrors = ({ errors = [], t, ...props }) => (
  <>
    {errors.length > 0 && (
      <div {...props}>
        <ul className="mt-3 list-disc list-none text-sm text-red">
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default AuthValidationErrors;
