import { useEffect, useState, useContext } from "react";

import Text from "../../Text/Text";
import Input from "../../Input/Input";
import { useRouter } from "next/router";
import Button from "../../Button/Button";
import EyeOpen from "../../Icons/EyeOpen";
import EyeClose from "../../Icons/EyeClose";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import UserContext from "@/context/UserContext";
import GoogleButton from "../../SocialLogin/GoogleButton";
import LinkedInButton from "../../SocialLogin/LinkedInButton";
import { SIGNUP_CHOOSE_PLAN_STEP } from "@/common/utils/constant";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

export default function CreateAccount({ setStep, privacyPolicy }) {
  const [passwordShown, setPasswordShown] = useState(false);
  const { register, user, updateUser } = useAuth();
  const { query } = useRouter();
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const togglePrivacyPolicyPopup = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };
  
  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;
  const t = appcontext.t;
  const locale = appcontext.locale;
  const { privacyPolicyEn, privacyPolicyAr } = useContext(AppContext);
  const removeHTMLTags = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || "";
  };

  const [errors, setErrors] = useState([]);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [feErrors, setFeErrors] = useState({
    email: false,
    password: false,
  });

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value });
    if (field === "name") return;
    if (field === "email") {
      const isValidEmail = /^[\w.%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/i.test(value);
      setFeErrors({ ...feErrors, [field]: !isValidEmail });
      if (!isValidEmail) {
        return;
      }
    }
    if (field === "password") {
      if (value !== "") {
        setFeErrors({ ...feErrors, [field]: false });
      } else {
        setFeErrors({ ...feErrors, [field]: true });
      }
    }
  };

  const checkEmailVAlidity = (name, eventTarget) => {

    if (!/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[a-z]{2,4}$/.test(eventTarget.value)) {

      const customMessage = locale == "ar" ? "الرجاء ادخال بريد الكتروني صحيح" : "Please enter a valid email"
  
      eventTarget.setCustomValidity(customMessage)
  
    } else {
  
      eventTarget.setCustomValidity('')
  
    }
  
    onChange(name, eventTarget.value)
  
  }

  const isValidated = () => {
    let isValid = true;
    const _errors = { ...feErrors };

    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === "" || val === undefined) {
          _errors[key] = true;
        } else {
          _errors[key] = false;
        }
        setFeErrors(_errors);
      }
    });
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false;
      }
    });
    return isValid;
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (isValidated()) {
      if (user) {
        updateUser({
          name: values.name,
          email: values.email,
          password: values.password,
          setErrors,
        });
      } else {
        const plan = query.plan;
        register({
          plan,
          name: values.name,
          email: values.email,
          password: values.password,
          setErrors,
        });
      }
    }
  };

  useEffect(() => {
    if (user) {
      const { name, email } = user;
      setValues({ name, email, password: "" });
      setStep(SIGNUP_CHOOSE_PLAN_STEP);
    }
  }, [user]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const showSignupModal = () => {
    setModalType("signin");
    setShowModal(true);
  };

  return (
    <div className="w-full sm:w-full md:w-[360px] lg:w-[420px] xl:w-[450px] mx-auto">
      <div>
        <Text as="h3" className="text-center text-lg font-bold">
          {t.signupHeading}
        </Text>
        <Text as="p" className="text-grey text-center font-medium">
          {t.signupSubHeading}
        </Text>
        <div className="pt-8 pb-[15px] flex-column items-center text-center justify-center sm:mr-0 lg:flex-row xl:flex xl:justify-between ">
          <GoogleButton className="mb-3 xl:mb-0 pr-[31px] xl:pr-[21px] py-[9px]" />
          <LinkedInButton className="text-base pr-[21px] py-[9px]" />
        </div>
        <div className="w-[280px] sm:w-[300px] md:w-[320px] lg:w-[320px] xl:w-[380px] relative text-center mx-auto z-10 mb-4 font-medium">
          <span className="absolute w-[280px] sm:w-[300px] md:w-[320px] lg:w-[320px] xl:w-[380px] border border-lightgrey border-t-1 border-b-0 top-3 left-0 -z-10"></span>
          <span className="p-4 bg-white z-40 text-lightgrey">{t.Or}</span>
        </div>
        {/* Validation Errors */}
        <AuthValidationErrors className="mb-4" errors={errors} t={t} />

        <form onSubmit={submitForm}>
          <div className="mb-4">
            <Input
              placeholder={t.Name + " (" + t.optional + ")"}
              className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                locale == "ar" ? "text-right rtl-input" : ""
              }`}
              defaultValue={values?.name}
              onChange={(event) => onChange("name", event.target.value)}
            />
            {feErrors.name && (
              <p
                className={`text-red text-sm ${
                  locale == "ar" ? "text-right" : ""
                }`}
              >
                {t.nameRequired}
              </p>
            )}
          </div>
          <div className="mb-4">
            <Input
              placeholder={t.Email}
              type="email"
              className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                locale == "ar" ? "text-right rtl-input" : ""
              }`}
              defaultValue={values?.email}
             // onChange={(event) => onChange("email", event.target.value)}
              onChange={(event) => checkEmailVAlidity("email", event.target)}
            />
            {feErrors.email && (
              <p
                className={`text-red text-sm ${
                  locale == "ar" ? "text-right" : ""
                }`}
              >
                {t.emailRequired}
              </p>
            )}
          </div>
          <div className="relative">
            <span
              className={`absolute z-10 top-[11px] cursor-pointer ${
                locale == "ar" ? "left-4" : "right-2"
              }`}
              onClick={togglePassword}
            >
              {!passwordShown && <EyeOpen />}
              {passwordShown && <EyeClose />}
            </span>
            <Input
              placeholder={t.Password}
              type={passwordShown ? "text" : "password"}
              className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                locale == "ar" ? "text-right rtl-input" : ""
              }`}
              onChange={(event) => onChange("password", event.target.value)}
            />
            {feErrors.password && (
              <p
                className={`text-red text-sm ${
                  locale == "ar" ? "text-right" : ""
                }`}
              >
                {t.passwordRequired}
              </p>
            )}
          </div>
          <div className="mx-2">
            <Text
              as="p"
              className={`text-sm text-grey800 mt-2 font-light ${
                locale == "ar" ? "text-right" : ""
              }`}
            >
              {t.agreePrivacyMessage}{" "}
              <span
                className="font-bold underline cursor-pointer"
                onClick={togglePrivacyPolicyPopup}
              >
                {t.privacyPolicy}
              </span>
              .
            </Text>
          </div>
          <div className="flex justify-center mx-auto mt-12 mb-8">
            <div className="flex-auto flex justify-center">
              <Button
                type="submit"
                className="btn btn-green btn-block rounded-full mt-0 font-bold"
                onClick={() => {
                  // Get the current URL and pass it to gtag_report_conversion
                  //const currentURL = process.env.NEXT_PUBLIC_FRONTEND_URL+'/signup';
                  gtag_report_conversion();
                }}
              >
                {user ? t.saveAndContinue : t.signupAndContinue}
              </Button>
            </div>
          </div>
        </form>
        <div className="flex items-center justify-center mt-2 mb-2">
          <Button
            onClick={() => showSignupModal()}
            className="hover:bg-transparent text-sm font-medium text-grey800 hover:text-gray-900 p-0"
          >
            {t.alreadyHaveAccount} &nbsp;
            <span className="font-semibold underline">{t.Login}</span>
          </Button>
        </div>
      </div>
     {/* ... (other JSX code) */}
     {showPrivacyPolicy && (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-black z-50">
          <div className="bg-white p-6 rounded-lg max-w-md">
            <div className="flex justify-between items-center mb-4">
              <Text as="h3" className="text-xl font-bold">
                {t.privacyPolicy}
              </Text>
              <Button
                onClick={togglePrivacyPolicyPopup}
                className="text-gray-600 hover:text-gray-800"
              >
                <i className="fas fa-times"></i>
              </Button>
            </div>
            <div className="text-sm text-gray-700 leading-relaxed">
              {locale === "en"
                ? removeHTMLTags(privacyPolicyEn)
                : removeHTMLTags(privacyPolicyAr)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}