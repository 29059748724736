import { useContext, useState, useEffect } from "react";

import Text from "../../Text/Text";
import Button from "../../Button/Button";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import CardPricing from "../../Cards/CardPricing";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";
import {
  SIGNUP_ADD_BILLING_STEP,
  SIGNUP_CREATE_ACCOUNT_STEP,
  SIGNUP_SUCCESS_STEP,
} from "@/common/utils/constant";
import UserContext from "@/context/UserContext";

export default function ChoosePlan({ setStep }) {
  const [selectedPlan, setPlan] = useState("");
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);

  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const setPlanType = usercontext.setPlanType;
  const planType = usercontext.planType;

  const { storeUserPlan } = useAuth({
    middleware: "auth",
  });
  useEffect(() => {
    if (selectedPlan) {
      savePlan();
    }
  }, [selectedPlan]);
  const savePlan = async () => {
    const plan = selectedPlan;
    const resp = await storeUserPlan({ plan, setErrors, setStatus });
    if (resp.plan) {
      setPlanType(resp.type);
      setStatus(true);
    }
  };
  const plans = appcontext.plans;
  const t = appcontext.t;
  const locale = appcontext.locale;
  const plan_details = locale == "en" ? appcontext.plansEN : appcontext.plansAR;
  const extraClasses = {
    mainHeadingClasses: "xl:text-[16px] font-semibold",
    pricingClasses: "xl:text-lg mt-[5px] mt-[2px]",
    pricingSupClasses: "text-sm md:text-sm lg:text-sm xl:text-sm",
    pricingSubClasses: "text-sm md:text-sm",
    descriptionClasses: "xl:text-sm",
    buttonClasses: "py-[7px] text-sm mt-[12px]",
    borderClasses: "mt-[16px]",
    trialClasses: "text-[8px]",
    badgeClasses: ` ${
      locale == "ar"
        ? "mr-1 text-[8px] md:text-[8px] lg:text-[9px] py-[2px] "
        : "text-sm md:text-[14px] lg:text-[10px] ml-1 py-[2px] text-[10px] "
    }`,
    badgeIconH: "10",
    badgeIconW: "10",
    featuresClasses: "text-[10px] mb-8px font-medium",
    featureIconWidth: "12",
    featureIconHeight: "12",
    checkboxWidth: 12,
  };
  const previousStep = () => {
    setStep(SIGNUP_CREATE_ACCOUNT_STEP);
  };
  useEffect(() => {
    if (status) {
      if (selectedPlan == 1000) {
        setStep(SIGNUP_SUCCESS_STEP);
      } else {
        setStep(SIGNUP_ADD_BILLING_STEP);
      }
    }
  }, [status]);

  return (
    <div className="pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[8px] relative pb-10">
      <AuthValidationErrors className="mb-4" errors={errors} t={t} />
      <div className="text-center">
        <div className="backbtn absolute left-1 md:left-4">
          <Button
            as="button"
            className="p-0 hover:bg-transparent active:rounded active:bg-transparent focus:ring-0"
            onClick={() => previousStep()}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16Z"
                stroke="#101820"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 10.6665L10.6667 15.9998"
                stroke="#101820"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M28 16H11"
                stroke="#101820"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 21.3333L10.6667 16"
                stroke="#101820"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
        <Text as="h2" className="text-lg mt-4 mb-[58px] font-bold">
          {t.chooseYourPlan}
        </Text>
      </div>
      <div className="w-full mt-[20px] md:flex md:justify-between lg:flex lg:justify-evenly xl:flex xl:justify-evenly gap-6">
        {plans?.map((plan, key) => {
          return (
            <CardPricing
              key={key}
              pricePlan={plan}
              planDetail={plan_details[key]}
              className={`w-full md:max-w-[200px] lg:max-w-[240px] xl:max-w-[275px] xl:p-[24px] xl:pt-2 ${
                plan.is_featured == "1" ? "" : "my-[20px]"
              }`}
              extraClasses={extraClasses}
              planBtnText={t.ContinueWithThisPlan}
              ajaxCheckout={true}
              setStep={setStep}
              setPlan={setPlan}
            />
          );
        })}
      </div>
    </div>
  );
}
