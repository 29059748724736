import styles from "./BlurBackground.module.scss";

const BlurBackground = ({ className = false }) => (
  <div className={`${styles.blurOverlayContainer} ${className}`}>
    <div className={styles.blurOverlay1}></div>
    <div className={styles.blurOverlay2}></div>
    <div className={styles.blurOverlay2}></div>
    <div className={styles.blurOverlay1}></div>
  </div>
);

export default BlurBackground;
