
import { useCookies } from 'react-cookie';
import { Oval } from "react-loader-spinner";
import { Frames, CardFrame } from "frames-react";
import { useState, useEffect, useContext } from "react";

import Input from "../Input/Input";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import { SIGNUP_SUCCESS_STEP } from "@/common/utils/constant";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";

export default function Checkout({ setStep }) {
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [status, setStatus] = useState(null);
  const { saveUserBilling } = useAuth({
    middleware: "auth",
  });
  const [toastMsg, seToastMsg] = useState('');
  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const [errors, setErrors] = useState([]);
  const [cookies, setCookie] = useCookies(['cookie-name']);

  const handleToastClose = () => {
    setOpen(false);
  };

  const onSetStep = (SIGNUP_SUCCESS_STEP) => {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(new Date().getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    setCookie('skip_card', true, { expires: nextDate });
    setStep(SIGNUP_SUCCESS_STEP);
  };

  const submitForm = async (e) => {
    if (e) {
      setLoader(true);
      const resp = await saveUserBilling({
        token: e.token,
        setErrors,
        setStatus,
      });
      setLoader(false);
      if (resp?._links) {
        window.location.href = resp?._links?.redirect?.href;
      }
      if (resp?.error_codes) {
        setErrors(resp?.error_codes);
      }
    }
  };

  useEffect(() => {
    if (status) {
      setStep(SIGNUP_SUCCESS_STEP);
    }
  }, [status]);
  return (
    <>
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
      <div className={`mb-4 mx-2 ${locale == "ar" ? "text-right" : ""}`}>
        <label>
          {locale == 'ar' && (
            <span className="text-grey200 ml-1">({t.optional})</span>
          )}
          {t.cardHolderName}
          {locale == 'en' && (
            <span className="text-grey200 ml-1">({t.optional})</span>
          )}
        </label>
        <Input
          placeholder="i.e. John Doe"
          className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent font-medium  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full text-black ${locale == "ar" ? "text-right rtl-input" : ""
            }`}
        />
      </div>
      <Frames
        config={{
          publicKey: process.env.NEXT_PUBLIC_CHECKOUT_KEY,
          ready: function () {
            document.querySelector(
              ".payment-method-logo-container"
            ).style.display = "none";
          },
          style: {
            base: {
              fontSize: "16px",
              color: "black",
              border: "1px solid #D1D2D4",
              padding: "0px 10px",
              borderRadius: "10px",
              minWidth: "80px",
              height: "50px",
            },
            placeholder: {
              base: {
                color: "#D1D2D4",
              },
            },
          },
        }}
        cardTokenized={(e) => {
          submitForm(e);
        }}
      >
        <CardFrame />
        <div className="mx-2">
          <button
            className="btn btn-green btn-block rounded-full font-semibold"
            onClick={() => {
              Frames.submitCard();
              if (!Frames.isCardValid()) {
                setOpen(true);
                seToastMsg('Invalid card details');
              }
            }}
          >
            {usercontext?.trialRemainingDays > 0 ? (
              t.startMyFreeTrial
            ) : (
              t.add_billing_details
            )}
            { }
            {loader && (
              <div className="ml-4">
                <Oval height="22" width="22" color="white" />
              </div>
            )}
          </button>
          <button
            onClick={() => onSetStep(SIGNUP_SUCCESS_STEP)}
            className="hover:bg-transparent text-sm font-medium text-grey800 hover:text-gray-900 p-0 text-center mt-3 flex justify-center mx-auto"
          >
            {t.t_skip_for_now}
          </button>
        </div>
      </Frames>
    </>
  )
};
