import React, { useContext } from "react";
import Stepper from "../SignupForm/Stepper";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import SigninForm from "../Forms/Signin/SigninForm";
import AddCardForm from "../Forms/UserForms/AddCardForm";
import BlurBackground from "../Background/BlurBackground";
import DeleteAccountForm from "../Forms/UserForms/DeleteAccountForm";
import ForgotPasswordForm from "../Forms/ForgotPassword/ForgotPassword";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import DisconnectSocialForm from "../Forms/UserForms/DisconnectSocialForm";
import SuggestedTranslation from "../Forms/SuggestedTranslation/SuggestedTranslation";

export default function ModalPopup({ showModal, type, setShowModal }) {
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;

  const usercontext = useContext(UserContext);
  const user = usercontext.user;
  var canShow = true;

  if (user && user.isCustom && type == "signup") {
    showModal = false;
  }
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className={`${showModal ? "slideup-model" : "slidedown-model"}
              } easeExtend top-0 w-[98%] sm:w-[98%] ${type == "suggested"
                  ? "md:w-[300px] lg:w-[400px] xl:w-[500px]"
                  : "md:w-[750px] lg:w-[950px] xl:w-[1040px]"
                } my-6 mx-auto max-w-3xl ${locale == "ar" ? "font-urwarabic" : ""
                }`}
            >
              <BlurBackground className="z-50" />
              {/*content*/}
              <div
                className={`border-0 relative rounded-lg shadow-lg relative flex flex-col w-full ${type == "suggested" ? "" : "min-h-modalFull"
                  } items-center bg-white outline-none focus:outline-none`}
              >
                {/*header*/}
                <div className="absolute right-0 px-5 pt-0 border-solid border-slate-200 rounded-t z-30">
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-thin outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className={`bg-transparent text-black opacity-5 text-2xl block outline-none focus:outline-none ${locale == "ar" ? "font-urwarabic" : "font-inter"
                      }`}>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-4 sm:px-2 md:px-3 lg:px-4 xl:px-6 flex-auto z-20 flex items-center">
                  {type == "suggested" && <SuggestedTranslation />}
                  {type == "signup" && canShow && <Stepper />}
                  {type == "signin" && <SigninForm />}
                  {type == "forgotPassword" && <ForgotPasswordForm />}
                  {type == "paymentCard" && (
                    <AddCardForm setShowModal={setShowModal} />
                  )}
                  {type == "socialDisconnect" && <DisconnectSocialForm />}
                  {type == "terms&conditions" && <TermsAndConditions />}
                  {type == "deleteaccount" && <DeleteAccountForm />}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-65 fixed inset-0 z-40 bg-pureblack"></div>
        </>
      ) : null}
    </>
  );
}
