import React from "react";
import IMG from "@/assets/images/background-mask.svg";

const CardBackground = ({ className = false }) => (
  <>
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        backgroundSize: "cover",
        overflow: "hidden",
        borderRadius: 24,
        zIndex: -1,
        backgroundImage: "url(" + IMG.src + ")",
      }}
      className={`${className}`}
    ></div>
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#00000012",
        position: "absolute",
        zIndex: -1,
        left: 0,
        top: 0,
        borderRadius: "24px",
      }}
    ></div>
  </>
);

export default CardBackground;
