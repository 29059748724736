import Text from "../../Text/Text";
import ErrorBot from "../../Icons/ErrorBot";
import { useContext, useState } from "react";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import SuccessBot from "../../Icons/SuccessBot";
import UserContext from "@/context/UserContext";
import { SIGNUP_ADD_BILLING_STEP } from "@/common/utils/constant";
import SuccessAnimation from "../../SuccessAnimation/SuccessAnimation";

export default function Success({ setStep }) {
  const { getUser } = useAuth({
    middleware: "auth",
  });

  const [errors, setErrors] = useState([]);
  const usercontext = useContext(UserContext);
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const planType = usercontext.planType;
  const setShowModal = usercontext.setShowModal;
  const setUserData = usercontext.setUserData;

  const fetchUserData = async () => {
    const resp = await getUser({ setErrors });
    setUserData(resp);
    setShowModal(false);
  };

  const signupComplete = () => {
    fetchUserData();
  };

  const addBillingInfo = () => {
    setStep(SIGNUP_ADD_BILLING_STEP);
  };
  return (
    <div className="w-full sm:w-[350px] md:w-[400px] lg:w-[450px] xl:w-[700px] pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[20px] relative pb-6 mx-auto">
      {usercontext?.trialRemainingDays > 0 ? (
        <>
          <div className="successBot mx-auto flex justify-center relative">
            <SuccessAnimation />
            <div className="absolute bottom-2">
              <SuccessBot />
            </div>
            <SuccessAnimation />
          </div>

          <div className="text-center">
            <Text
              as="h2"
              className="text-[20px] sm:text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] mt-4 mb-4 font-semibold text-black"
            >
              {t.signup_complete_text}
            </Text>
            <Text as="p" className={`text-md text-grey800 font-medium ${locale == 'ar' ? 'rtl-input' : ''}`}>
              {planType !== "free" && (
                <>
                  
                  {usercontext?.trialRemainingDays && locale === 'en' && (
                      t.free_days_trial_1 + ' ' + usercontext?.trialRemainingDays + ' ' + t.days + ' ' + t.free_days_trial_2
                    )
                  }
                  {usercontext?.trialRemainingDays && locale === 'ar' && (
                      t.free_days_trial_1 + ' ' + t.free_days_trial_2 + usercontext?.trialRemainingDays + ' ' + t.days
                    )
                  }
                  <br/>
                  {usercontext?.trialRemainingDays > 3 ? (
                    t.signup_success
                  ) : (
                    t.signup_trial_expire_soon
                  )}
                </>
              )}
            </Text>
          </div>
          <div className="flex mt-4 justify-center mx-auto mt-12 mb-8 w-[90%] sm-[80%] md:w-[170px] lg:w-[200px] xl:w-[224px]">
            <div className="flex-auto flex justify-center">
              <button
                className="btn btn-green btn-block rounded-full"
                onClick={() => signupComplete()}
              >
                {t.start_translating}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="successBot mx-auto flex justify-center relative pt-16">
              <div className="absolute bottom-2">
                <ErrorBot />
              </div>
            </div>

            <div className="text-center">
              <Text
                as="h2"
                className="text-[20px] sm:text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] mt-4 mb-4 font-semibold text-black"
              >
                {t.trial_expired}
              </Text>
              <Text as="p" className="text-md text-grey800 font-medium">
                {planType !== "free" && (
                  <>
                    {t.add_billing_details_to_start_translation}
                  </>
                )}
              </Text>
            </div>
            <div className="flex mt-4 justify-center mx-auto mt-12 mb-8 w-[90%] sm-[80%] md:w-[170px] lg:w-[200px] xl:w-[224px]">
              <div className="flex-auto flex justify-center">
                <button
                  className="btn btn-green btn-block rounded-full"
                  onClick={() => addBillingInfo()}
                >
                  {t.add_billing_details}
                </button>
              </div>
            </div>
        </>
      )}

    </div>
  );
}
