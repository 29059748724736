import React, { useContext, useEffect, useState } from "react";

import Text from "../Text/Text";
import Badge from "../Badge/Badge";
import Button from "../Button/Button";
import { PopupButton } from "react-calendly";
import AppContext from "@/context/AppContext";
import { isAndroid, isMobile } from "react-device-detect";
import UserContext from "@/context/UserContext";
import CheckIcon from "@/assets/icons/CheckIcon";
import StarBlackIcon from "../Icons/StarBlackIcon";
import { CONTACT_US_LINK } from "@/common/utils/constant";
import CardBackground from "../Background/CardBackground";

const CardPricing = React.forwardRef(
  (
    {
      extraClasses = [],
      className,
      pricePlan,
      planDetail,
      planBtnText = "",
      ajaxCheckout = false,
      setPlan = "",
    },
    ref
  ) => {
    const usercontext = useContext(UserContext);
    const appcontext = useContext(AppContext);
    const [showHobbyistDetails, setShowHobbyistDetails] = useState(false);
    const [showStartUpDetails, setShowStartUpDetails] = useState(false);
    const [showBusinessDetails, setShowBusinessDetails] = useState(false);
    const [rootDiv, setRootDiv] = useState("");
    const locale = appcontext.locale;
    const t = appcontext.t;
    const setShowModal = usercontext.setShowModal;
    const setModalType = usercontext.setModalType;
    const checkoutpage = (id, type) => {
      if (ajaxCheckout) {
        if (type !== "custom") {
          setPlan(id + "000");
        }
      } else {
        if (type !== "custom") {
          setShowModal(true);
          setModalType("signup");
        }
      }
    };
    const showHidePlanDetails = (planDetail, showDetails) => {
      if (planDetail.id === 1) setShowHobbyistDetails(showDetails);
      else if (planDetail.id === 2) setShowStartUpDetails(showDetails);
      else if (planDetail.id === 3) setShowBusinessDetails(showDetails);
    };
    if (pricePlan.is_active !== "1") {
      return null;
    }
    useEffect(() => {
      if (document) {
        var root = document.getElementById("__next");
        setRootDiv(root);
      }
    }, [])
    return (
      <div
        className={`${pricePlan.is_featured == "1" ? "text-black bg-white" : "bg-white"}
            ${isMobile ? 'min-w-fit h-fit' : ''}
            ${locale == "ar" ? "font-urwarabic text-right" : ""}
            ${className} relative shadow-xl p-[18px] sm:p-[20px] md:p-[24px] lg:p-[28px] xl:p-[32px] rounded-md z-0`}
      >
        {pricePlan.is_featured == "1" ? (
          <>
            <CardBackground />
          </>
        ) : null}
        <div className="z-0">
          <span className="badge-red"></span>
          <span className="badge-green"></span>
          <span className="badge-blue"></span>
          <span className="badge-yellow"></span>

          {(pricePlan.is_featured == "1" ||
            extraClasses.recommendedBadgeText) && (
              <div
                className={`absolute top-[-13px] featured-card-background ${locale == "ar" ? "left-[25px]" : "right-[25px]"
                  } `}
              >
                <Badge
                  text={
                    extraClasses.recommendedBadgeText
                      ? extraClasses.recommendedBadgeText
                      : t.FreeTrial
                  }
                  color="yellow"
                  icon={StarBlackIcon}
                  className={
                    extraClasses.badgeClasses +
                    ` flex items-center justify-center ${locale == "ar"
                      ? "flex-row-reverse font-bold"
                      : "font-semibold"
                    }`
                  }
                />
              </div>
            )}
          <div
            className={`price-header ${pricePlan.is_featured == "1" ? "border-grey" : ""
              }`}
          >
            <Text
              as="h4"
              className={`${pricePlan.is_featured == "1" ? "text-black" : "text-black"
                } text-lg md:text-normal flex items-center ${locale == "ar"
                  ? `font-urwarabic text-right ${pricePlan.is_featured == "1"
                    ? "items-end flex-row-reverse"
                    : "items-center flex-row-reverse"
                  }`
                  : "text-left"
                } md:leading-[25px] lg:leading-md pt-[14px] sm:pt-[10px] md:pt-[10px] lg:pt-[12px] xl:pt-[16px] ${extraClasses.mainHeadingClasses
                }`}
            >
              {planDetail?.name}

              {planDetail?.badge && (
                <Badge
                  text={planDetail?.badge?.title}
                  color={planDetail?.badge?.color}
                  className={extraClasses.badgeClasses}
                />
              )}
            </Text>
            <Text
              as="h3"
              className={`${pricePlan.is_featured == "1"
                ? "text-black"
                : "text-red capitalize"
                }  text-center ${extraClasses.pricingClasses} ${locale == "ar" ? "font-urwarabic lg:text-right" : "lg:text-left"
                } font-bold md:leading-[25px] lg:leading-md`}
            >
              {!isNaN(planDetail?.price) && (
                <sup
                  className={`${extraClasses.pricingSupClasses} symbol font-semibold`}
                >
                  {planDetail?.symbol}
                </sup>
              )}
              {planDetail?.price}
              {!isNaN(planDetail?.price) && (
                <sub
                  className={`${extraClasses.pricingSubClasses} font-normal text-black`}
                >
                  {pricePlan.durations == "monthly"
                    ? t.PerUserPerMonth
                    : t.PerUserPerYear}
                </sub>
              )}
            </Text>
            <Text
              style={{ minHeight: 55, maxWidth: isMobile ? 250 : 'auto', minWidth: isMobile ? 250 : '' }}
              as="p"
              className={`${pricePlan.is_featured == "1" ? "text-black opacity-64" : ""
                } ${extraClasses.descriptionClasses
                  ? extraClasses.descriptionClasses
                  : "text-sm md:text-md lg:text-md xl:text-base"
                }  text-center sm:text-center m-auto ${locale == "ar"
                  ? "md:text-right font-medium md:text-right lg:text-right"
                  : "md:text-left font-medium md:text-left lg:text-left"
                } ${pricePlan.is_featured == "1"
                  ? "text-black opacity-64"
                  : "text-lightgrey"
                } mt-[16px]`}
            >
              {planDetail?.description}
            </Text>
            {pricePlan.type == 'custom' ? (
              <PopupButton
                url={process.env.NEXT_PUBLIC_CALENDLY_MEETING_LINK}
                rootElement={rootDiv}
                text={planBtnText !== "" ? planBtnText : planDetail?.button_text}
                pageSettings={{
                  height: "100vh",
                  primaryColor: "#00A499",
                  marginTop: "0px !important",
                }}
                className={`${extraClasses.buttonClasses
                  } mt-[16px] font-semibold btn-outline-green btn-block hover:bg-darkgreen rounded-full hover:rounded-full`}
              />
            ) : (
              <Button
                style={pricePlan.is_featured == "1" ? { marginTop: 2 } : null}
                type="button"
                className={`${pricePlan.is_featured == "1" ? "bg-green text-white" : ""
                  } ${extraClasses.buttonClasses
                  } mt-[16px] font-semibold btn-outline-green btn-block hover:bg-darkgreen rounded-full hover:rounded-full`}
                onClick={() => checkoutpage(pricePlan.id, pricePlan.type)}
              >
                {planBtnText !== "" ? planBtnText : planDetail?.button_text}
              </Button>
            )}

            {planDetail?.text_under_button && (
              <Text
                as="p"
                className={`text-black text-center mt-[8px] font-bold ${extraClasses.trialClasses
                  ? extraClasses.trialClasses
                  : "text-[12px]"
                  }`}
              >
                {planDetail?.text_under_button}
              </Text>
            )}
          </div>
          <div
            className={`border-b w-full h-[1px] mt-6 ${extraClasses.borderClasses
              } ${pricePlan.is_featured == "1"
                ? "bg-black opacity-[0.1] border-black"
                : ""
              }`}
          ></div>
          {pricePlan.featureTitle && (
            <Text as="h6" className="mt-[12px]">
              {pricePlan.featureTitle}
            </Text>
          )}

          <Text as="h6"
            className={`mt-[12px] text-[14px] font-semibold block md:hidden text-center cursor-pointer
            ${pricePlan.id === 1 && !showHobbyistDetails ||
                pricePlan.id === 2 && !showStartUpDetails ||
                pricePlan.id === 3 && !showBusinessDetails ? 'block' : 'hidden'}`}
            onClick={() => showHidePlanDetails(pricePlan, true)}>
            {t.moreDetails}
          </Text>
          <Text as="h6"
            className={`mt-[12px] text-[14px] font-semibold block md:hidden text-center cursor-pointer
            ${pricePlan.id === 1 && showHobbyistDetails ||
                pricePlan.id === 2 && showStartUpDetails ||
                pricePlan.id === 3 && showBusinessDetails ? 'block' : 'hidden'}`}
            onClick={() => showHidePlanDetails(pricePlan, false)}>
            {t.lessDetails}
          </Text>
          <div className={`${pricePlan.id === 1 && showHobbyistDetails ||
            pricePlan.id === 2 && showStartUpDetails ||
            pricePlan.id === 3 && showBusinessDetails ||
            !isMobile ? 'block' : 'hidden'}`} >
            <ul className={`pricing-feature mt-4 overflow-scroll md:overflow-visible ${isMobile ? 'max-h-28' : ''}`}>
              {planDetail?.features &&
                planDetail?.features?.map((feature, index) => (
                  <li
                    key={index}
                    className={`flex flex-row items-center ${pricePlan.is_featured == "1" ? "text-grey" : "text-grey"
                      } ${extraClasses.featuresClasses
                      } text-base font-medium opacity-90 ${locale == "ar" ? "flex rtl-input" : ""
                      }`}
                  >
                    <span className={`${locale == "ar" ? "ml-2" : "mr-2"}`}>
                      <CheckIcon width={extraClasses.checkboxWidth} />
                    </span>{" "}
                    {feature}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div >
    );
  }
);
export default CardPricing;
