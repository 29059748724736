import { useContext } from "react";
import AppContext from "../../../../context/AppContext";
import Text from "../Text/Text";

const Badge = ({ text, color, icon = false, className = "" }) => {
  const Icon = icon;
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;
  return (
    <Text as="span" className={`badge badge-${color} ml-[16px] ${className}`}>
      {icon && (
        <span className={`${locale == "ar" ? "pl-[5px]" : "pr-[5px]"}`}>
          <Icon color={color} />
        </span>
      )}
      {text}
    </Text>
  );
};

export default Badge;
