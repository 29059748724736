import { useState, useContext, useEffect } from "react";

import Text from "../../Text/Text";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import MailBox from "../../Icons/MailBox";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "../../../../../context/AppContext";
import AuthSessionStatus from "../../AuthSessionStatus/AuthSessionStatus";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";


export default function ForgotPasswordForm() {
  const { forgotPassword } = useAuth({
    middleware: "guest",
    userRedirectIfAuthenticated: process.env.NEXT_PUBLIC_FRONTEND_URL,
    adminRedirectIfAuthenticated: "/admin/dashboard",
  });
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;

  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });
  const [feErrors, setFeErrors] = useState({
    email: false,
  });

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value });

    if (value !== "") {
      setFeErrors({ ...feErrors, [field]: false });
    } else {
      setFeErrors({ ...feErrors, [field]: true });
    }
  };

  const isValidated = () => {
    let isValid = true;
    const _errors = { ...feErrors };

    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === "" || val === undefined) {
          _errors[key] = true;
        } else {
          _errors[key] = false;
        }
        setFeErrors(_errors);
      }
    });
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false;
      }
    });
    return isValid;
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (isValidated()) {
      event.preventDefault();
      setLoader(true);
      forgotPassword({ email: values.email, setErrors, setLoader, setStatus });
    }
  };

  useEffect(() => {
    if (status) {
      setLoader(false);
    }
  }, [status]);
  return (
    <div className="w-full fade-in-step sm:w-[300px] md:w-[350px] lg:w-[400px] xl:w-[424px] pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[20px] relative pb-6 mx-auto">
      {loader && <Loader className="left-[38%] top-[8%]" />}
      {status === null ? (
        <>
          <div className="text-center">
            <Text as="h2" className="text-lg my-4 font-semibold">
              {t.resetPasswordTitle}
            </Text>
            <Text as="p" className="text-md mb-32 text-lightgrey">
              {t.resetPasswordSubtitle}
            </Text>
          </div>
          <AuthSessionStatus className="mb-4" status={status} t={t} />

          <AuthValidationErrors className="mb-4" errors={errors} t={t} />
          <form onSubmit={submitForm}>
            <div className="mb-4">
              <Input
                placeholder={t.Email}
                type="email"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                  locale == "ar" ? "text-right rtl-input" : ""
                }`}
                defaultValue={values?.email}
                onChange={(event) => onChange("email", event.target.value)}
              />
              {feErrors.email && (
                <p
                  className={`text-red text-sm ${
                    locale == "ar" ? "text-right" : ""
                  }`}
                >
                  {t.emailRequired}
                </p>
              )}
            </div>

            <div className="flex mt-4 justify-center mx-auto mt-9 mb-8">
              <div className="flex-auto flex justify-center">
                <Button
                  type="submit"
                  className="btn btn-green btn-block rounded-full mt-0"
                >
                  {t.resetMyPassword}
                </Button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <div className="flex items-center justify-center fade-in-step flex-col text-center">
          <div className="mailbox-icon">
            <MailBox />
          </div>
          <Text as="h3" className="mt-4 text-xl font-semibold">
            {t.checkYourInbox}
          </Text>
          <Text as="p" className="mt-4 text-md text-lightgrey text-[16px]">
            {t.emailSentMessage}{" "}
            <span className="text-red">{values.email}</span>
          </Text>
        </div>
      )}
    </div>
  );
}
