import { useContext } from "react";

import "./Stepper.module.css";
import Success from "./Steps/Success";
import ChoosePlan from "./Steps/ChoosePlan";
import BillingInfo from "./Steps/BillingInfo";
import UserContext from "@/context/UserContext";
import CreateAccount from "./Steps/CreateAccount";
import {
  SIGNUP_ADD_BILLING_STEP,
  SIGNUP_CHOOSE_PLAN_STEP,
  SIGNUP_CREATE_ACCOUNT_STEP,
  SIGNUP_SUCCESS_STEP,
} from "@/common/utils/constant";

export default function Stepper() {
  const usercontext = useContext(UserContext);
  const step = usercontext.step;
  const setStep = usercontext.setStep;
  return (
    <div className="py-3 sm:py-4 md:py-6 lg:py-8 xl:py-8">
      <div className="px-4 mt-4 sm:mt-auto sm:px-4 w-[90%] sm:w-[400px] md:w-[480px] lg:w-[550px] xl:w-[630px] mx-auto">
        <div
          className={`flex items-center ${
            step == SIGNUP_SUCCESS_STEP ? `hidden` : ""
          }`}
        >
          <div className="flex items-center text-teal-600 relative">
            <div className="rounded-full transition duration-500 ease-in-out h-7 w-7 border-4 border-white bg-red"></div>
          </div>
          <div
            className={`flex-auto border-t-2 ${
              step >= SIGNUP_CHOOSE_PLAN_STEP
                ? "border-red transition fadeInOut"
                : "border-gray-300"
            }`}
          ></div>
          <div className="flex items-center text-white relative">
            <div
              className={`rounded-full h-7 w-7 border-4 border-white ${
                step >= SIGNUP_CHOOSE_PLAN_STEP
                  ? "bg-red transition"
                  : "bg-gray-300"
              }`}
            ></div>
          </div>
          <div
            className={`flex-auto border-t-2 ${
              step >= SIGNUP_ADD_BILLING_STEP
                ? "border-red transition fadeInOut"
                : "border-gray-300"
            }`}
          ></div>
          <div className="flex items-center text-gray-500 relative">
            <div
              className={`rounded-full h-7 w-7 border-4 border-white ${
                step >= SIGNUP_ADD_BILLING_STEP
                  ? "bg-red transition"
                  : "bg-gray-300"
              }`}
            ></div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        {step == SIGNUP_CREATE_ACCOUNT_STEP && (
          <div
            className={`${
              step == SIGNUP_CREATE_ACCOUNT_STEP
                ? "fade-in-step"
                : "fade-out-step"
            }`}
          >
            <CreateAccount setStep={setStep} />
          </div>
        )}
        {step == SIGNUP_CHOOSE_PLAN_STEP && (
          <div
            className={`${
              step == SIGNUP_CHOOSE_PLAN_STEP ? "fade-in-step" : "fade-out-step"
            }`}
          >
            <ChoosePlan setStep={setStep} />
          </div>
        )}
        {step == SIGNUP_ADD_BILLING_STEP && (
          <div
            className={`${
              step == SIGNUP_ADD_BILLING_STEP ? "fade-in-step" : "fade-out-step"
            }`}
          >
            <BillingInfo setStep={setStep} />
          </div>
        )}
        {step == SIGNUP_SUCCESS_STEP && (
          <div
            className={`${
              step == SIGNUP_SUCCESS_STEP ? "fade-in-step" : "fade-out-step"
            }`}
          >
            <Success setStep={setStep} />
          </div>
        )}
      </div>
    </div>
  );
}
