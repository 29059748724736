import React, { useContext, useState } from "react";

import Button from "../../Button/Button";
import EyeOpen from "../../Icons/EyeOpen";
import EyeClose from "../../Icons/EyeClose";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import AuthSessionStatus from "../../AuthSessionStatus/AuthSessionStatus";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

const DeleteAccountForm = () => {
  const { deleteAccount } = useAuth({
    middleware: "auth",
  });
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;

  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);

  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] =
    useState(false);
  const [values, setValues] = useState({
    password: "",
    password_confirmation: "",
  });
  const [feErrors, setFeErrors] = useState({
    password: false,
    password_confirmation: false,
  });

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value });

    if (value !== "") {
      setFeErrors({ ...feErrors, [field]: false });
    } else {
      setFeErrors({ ...feErrors, [field]: true });
    }
  };

  const isValidated = () => {
    let isValid = true;
    const _errors = { ...feErrors };

    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === "" || val === undefined) {
          _errors[key] = true;
        } else {
          _errors[key] = false;
        }
        setFeErrors(_errors);
      }
    });
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false;
      }
    });
    return isValid;
  };

  const togglePassword = (type) => {
    if (type == "password") {
      setNewPasswordShown(!newPasswordShown);
    } else if (type == "confirm") {
      setPasswordConfirmationShown(!passwordConfirmationShown);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (isValidated()) {
      // delete account
      const resp = await deleteAccount({
        password: values.password,
        password_confirmation: values.password_confirmation,
        setErrors,
        setStatus,
      });
      if (resp?.status == 200) {
        setStatus(resp.message);
        setTimeout(() => {
          window.location.href = "/" + locale;
        }, 2000);
      }
    }
  };
  return (
    <div className="flex-auto">
      {/* Session Status */}
      <AuthSessionStatus className="mb-4" status={status} t={t} />

      {/* Validation Errors */}
      <AuthValidationErrors className="mb-4" errors={errors} t={t} />

      <h6 className="text-black text-lg text-center mt-3 mb-9 font-bold flex flex-wrap m-auto sm:w-full md:w-[80%] lg:w-[380px] xl:w-[424px]">
        Not so fast, You will need to set a password to be able to delete your
        account
      </h6>
      <form onSubmit={handleDelete}>
        <div className="flex flex-wrap m-auto sm:w-full md:w-[80%] lg:w-[380px] xl:w-[424px]">
          <div className="relative w-full">
            <label
              className={`block text-black text-[16px] font-medium mb-2 ${
                locale == "ar" ? "text-right" : ""
              }`}
              htmlFor="grid-password"
            >
              Password <span className="text-red">*</span>
            </label>
            <div className="relative mb-4">
              <span
                className={`absolute z-10 top-[11px] cursor-pointer ${
                  locale == "ar" ? "left-4" : "right-2"
                }`}
                onClick={() => togglePassword("password")}
              >
                {!newPasswordShown && <EyeOpen />}
                {newPasswordShown && <EyeClose />}
              </span>
              <input
                placeholder={t.Password}
                type={newPasswordShown ? "text" : "password"}
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                  locale == "ar" ? "text-right rtl-input" : ""
                }`}
                value={values.password}
                onChange={(event) => onChange("password", event.target.value)}
              />
              {feErrors.password && (
                <p
                  className={`text-red text-sm ${
                    locale == "ar" ? "text-right" : ""
                  }`}
                >
                  {t.passwordRequired}
                </p>
              )}
            </div>
          </div>
          <div className="relative w-full mb-14">
            <label
              className={`block text-black text-[16px] font-medium mb-2 ${
                locale == "ar" ? "text-right" : ""
              }`}
              htmlFor="grid-password"
            >
              Confirm Password <span className="text-red">*</span>
            </label>
            <div className="relative mb-4">
              <span
                className={`absolute z-10 top-[11px] cursor-pointer ${
                  locale == "ar" ? "left-4" : "right-2"
                }`}
                onClick={() => togglePassword("confirm")}
              >
                {!passwordConfirmationShown && <EyeOpen />}
                {passwordConfirmationShown && <EyeClose />}
              </span>
              <input
                placeholder={"Confirm Password"}
                type={passwordConfirmationShown ? "text" : "password"}
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${
                  locale == "ar" ? "text-right rtl-input" : ""
                }`}
                value={values.password_confirmation}
                onChange={(event) =>
                  onChange("password_confirmation", event.target.value)
                }
              />
              {feErrors.password_confirmation && (
                <p
                  className={`text-red text-sm ${
                    locale == "ar" ? "text-right" : ""
                  }`}
                >
                  {t.passordConfirmationRequired}
                </p>
              )}
            </div>
          </div>
          <Button
            className="btn-green block w-full py-3 rounded-full px-8 font-medium mt-0"
            type="submit"
          >
            {t.confirm}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DeleteAccountForm;
