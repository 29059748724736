export default function StarBlackIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5209 0.604734C6.66299 0.128786 7.33701 0.128787 7.4791 0.604735L8.58355 4.30404C8.64806 4.52012 8.84923 4.66627 9.07467 4.66085L12.9342 4.5681C13.4308 4.55617 13.6391 5.19719 13.2303 5.47941L10.0534 7.67295C9.86779 7.80108 9.79095 8.03756 9.86577 8.25029L11.1466 11.8923C11.3114 12.3608 10.7662 12.757 10.3714 12.4555L7.30353 10.1119C7.12433 9.97498 6.87567 9.97498 6.69647 10.1119L3.62856 12.4555C3.23385 12.757 2.68856 12.3608 2.85335 11.8923L4.13423 8.25029C4.20905 8.03756 4.13221 7.80107 3.94664 7.67295L0.769689 5.47941C0.360946 5.19719 0.569229 4.55617 1.06579 4.5681L4.92533 4.66085C5.15077 4.66627 5.35194 4.52012 5.41645 4.30404L6.5209 0.604734Z"
        fill="#101820"
      />
    </svg>
  );
}
