import { useContext, useEffect } from "react";
import AppContext from "../../../../../context/AppContext";
import Text from "../../Text/Text";
import Checkout from "../../PaymentForms/Checkout";
import Hyperpay from "../../PaymentForms/Hyperpay";

export default function BillingInfo({ setStep }) {

  const appcontext = useContext(AppContext);
  const t = appcontext.t;

  return (
    <div className="w-full sm:w-[350px] md:w-[400px] lg:w-[450px] xl:w-[550px] pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[20px] relative pb-6 mx-auto">
      <div className="text-center">
        <Text as="h2" className="text-lg mt-4 mb-[40px] font-bold">
        {t.addBillingInfo}
        </Text>
      </div>
      {appcontext.paymentGateway == 'checkout' && <Checkout setStep={setStep} />}
      {appcontext.paymentGateway == 'hyperpay' && <Hyperpay setStep={setStep} />}
    </div>
  );
}
