import { useContext, useState } from "react";

import Button from "../Button/Button";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";

export default function LinkedInButton({ className }) {
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const { socialAuthLogin } = useAuth({
    middleware: "guest",
  });
  const [errors, setErrors] = useState([]);
  const linkedinLogin = async () => {
    let type = "linkedin";
    let resp = await socialAuthLogin({ setErrors, type });
    if (resp.status == 200) {
      window.location.href = resp.data;
    } else if (resp.status == 422) {
      setErrors(resp.errors);
    }
  };
  return (
    <div>
      <Button
        type="button"
        className={`border border-gray-200 rounded ${className}`}
        onClick={() => linkedinLogin()}
      >
        <span className="pr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 1.74545V22.2545C24 23.227 23.215 24 22.2555 24H1.74455C0.772586 24 0 23.2145 0 22.2545V1.74545C0 0.772987 0.785047 0 1.74455 0H22.243C23.215 0 24 0.785455 24 1.74545ZM7.00312 9.00156H3.50156V20.5091H7.00312V9.00156ZM7.30218 5.24883C7.31464 4.11429 6.40498 3.19169 5.27103 3.16675C4.13707 3.15429 3.21495 4.06441 3.19003 5.19896C3.17757 6.33351 4.08723 7.2561 5.22119 7.28104H5.24611C6.35514 7.30597 7.2648 6.43325 7.30218 5.3361V5.24883ZM20.4984 13.6145C20.4984 10.1112 18.1807 8.75221 16.1745 8.75221C14.1682 8.75221 12.6729 9.92415 12.3489 10.6223H12.2991V9.00156H8.99688V20.5091H12.4984V14.4997C12.4984 12.6545 13.757 11.8317 14.9782 11.8317C15.9751 11.8317 17.0093 12.6047 17.0093 14.4499V20.4966H20.5109V13.6145H20.4984Z"
              fill="#0A66C2"
            />
          </svg>{" "}
        </span>
        {t.continueWithLinkedIn}
      </Button>
    </div>
  );
}
