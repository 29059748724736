import { useRouter } from "next/router";
import React, { useContext } from "react";
import AppContext from "../../../../context/AppContext";

const TermsAndConditions = () => {
  const appcontext = useContext(AppContext);
  const router = useRouter();
  const { locale } = router;
  const terms =
    locale == "ar"
      ? appcontext?.termsConditionsAR ?? ""
      : appcontext?.termsConditions ?? "";
  return (
    <div
      className={`flex-auto terms-conditons-content px-4 ${
        locale == "ar" ? "text-right font-urwarabic" : ""
      }`}
      dangerouslySetInnerHTML={{ __html: terms }}
    ></div>
  );
};

export default TermsAndConditions;
