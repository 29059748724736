export default function EyeOpen() {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_263_11050)">
        <path
          d="M13.5604 14C14.7313 14 15.6804 13.1046 15.6804 12C15.6804 10.8954 14.7313 10 13.5604 10C12.3896 10 11.4404 10.8954 11.4404 12C11.4404 13.1046 12.3896 14 13.5604 14Z"
          stroke="#101820"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1605 12C21.3334 16.667 17.8005 19 13.5604 19C9.32045 19 5.78747 16.667 2.96045 12C5.78747 7.333 9.32045 5 13.5604 5C17.8005 5 21.3334 7.333 24.1605 12Z"
          stroke="#101820"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_263_11050">
          <rect
            width="25.44"
            height="24"
            fill="white"
            transform="translate(0.840332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
