import { useState, useContext } from "react";

import Swal from "sweetalert2";
import Text from "../../Text/Text";
import Button from "../../Button/Button";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import TranslationContext from "@/context/TranslationContext";
import AuthSessionStatus from "../../AuthSessionStatus/AuthSessionStatus";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

export default function SuggestedTranslation() {
  const { suggestedTranslation } = useAuth({
    middleware: "auth",
    userRedirectIfAuthenticated: process.env.NEXT_PUBLIC_FRONTEND_URL,
    adminRedirectIfAuthenticated: "/admin/dashboard",
  });
  const appcontext = useContext(AppContext);
  const translationcontext = useContext(TranslationContext);
  const usercontext = useContext(UserContext);

  const translations = translationcontext.translations;
  const t = appcontext.t;
  const setShowModal = usercontext.setShowModal;

  const [sTranslation, setSTranslation] = useState(translations.translatedText);
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);

  const submitForm = async (event) => {
    event.preventDefault();
    let translationId = translations.translationId;
    const resp = await suggestedTranslation({
      sTranslation,
      translationId,
      setErrors,
      setStatus,
    });
    if (resp.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: resp.message,
      }).then((result) => {
        if (result.isConfirmed) {
          setShowModal(false);
        }
      });
    }
  };

  return (
    <div className="w-full fade-in-step sm:w-[300px] md:w-[350px] lg:w-[400px] xl:w-[424px] relative mx-auto">
      <>
        <div className="">
          <Text as="h2" className="text-[24px] my-4 font-semibold">
            {t.suggestedEdit}
          </Text>
          <Text
            as="p"
            className="text-[16px] mb-1 text-lightgrey font-semibold"
          >
            {t.originalText}
          </Text>
          <Text as="p" className="mb-6 text-black text-[16px] font-normal">
            {translations.sourceText}
          </Text>
        </div>
        <AuthSessionStatus className="mb-4" status={status} t={t} />

        <AuthValidationErrors className="mb-4" errors={errors} t={t} />
        <form onSubmit={submitForm}>
          <div className="">
            <label className="text-lightgrey text-[16px] font-semibold">
              {t.suggestedTranslation}
            </label>
            <textarea
              className="py-2 px-4 mt-2 h-28 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full font-urwarabic"
              onChange={(event) => setSTranslation(event.target.value)}
              defaultValue={translations.translatedText}
            ></textarea>
          </div>

          <div className="flex mt-4 justify-center mx-auto mt-9 mb-8">
            <div className="flex-auto flex justify-center">
              <Button
                type="submit"
                className="btn btn-red rounded-full mt-0 py-2 px-12"
              >
                {t.continue}
              </Button>
            </div>
          </div>
        </form>
      </>
    </div>
  );
}
