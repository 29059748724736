import { useState, useEffect, useContext } from "react";

import Text from "../../Text/Text";
import Input from "../../Input/Input";
import { useRouter } from "next/router";
import Button from "../../Button/Button";
import EyeOpen from "../../Icons/EyeOpen";
import EyeClose from "../../Icons/EyeClose";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import GoogleButton from "../../SocialLogin/GoogleButton";
import LinkedInButton from "../../SocialLogin/LinkedInButton";
import AuthSessionStatus from "../../AuthSessionStatus/AuthSessionStatus";
import TranslationContext from "../../../../../context/TranslationContext";
import AuthValidationErrors from "../../AuthValidationErrors/AuthValidationErrors";

export default function SigninForm() {
  const router = useRouter();
  const { login } = useAuth({
    middleware: "guest",
    adminRedirectIfAuthenticated: "/admin/dashboard",
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const translationcontext = useContext(TranslationContext);
  const setLimitExceed = translationcontext.setLimitExceed;
  const setDocLimitExceed = translationcontext.setDocLimitExceed;
  const usercontext = useContext(UserContext);
  const user = usercontext.user;

  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;
  const [rememberMe, setRememberMe] = useState(false);

  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [feErrors, setFeErrors] = useState({
    email: false,
    password: false,
  });

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value });

    if (value !== "") {
      setFeErrors({ ...feErrors, [field]: false });
    } else {
      setFeErrors({ ...feErrors, [field]: true });
    }
  };


  const checkEmailVAlidity = (name, eventTarget) => {
    if (!/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[a-z]{2,4}$/.test(eventTarget.value)) {

      const customMessage = locale == "ar" ? "الرجاء ادخال بريد الكتروني صحيح" : "Please enter a valid email"

      eventTarget.setCustomValidity(customMessage)

    } else {

      eventTarget.setCustomValidity('')

    }

    onChange(name, eventTarget.value)

  }

  const isValidated = () => {
    let isValid = true;
    const _errors = { ...feErrors };

    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === "" || val === undefined) {
          _errors[key] = true;
        } else {
          _errors[key] = false;
        }
        setFeErrors(_errors);
      }
    });
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false;
      }
    });
    return isValid;
  };

  const submitForm = (event) => {
    setLoading(true);
    event.preventDefault();
    if (isValidated()) {
      if (rememberMe) {
        if (values.email !== "" && values.password != "") {
          localStorage.email = values.email;
          localStorage.password = values.password;
          localStorage.checkbox = rememberMe;
        }
      } else {
        localStorage.email = "";
        localStorage.password = "";
        localStorage.checkbox = rememberMe;
      }
      login({
        email: values.email?.toLocaleLowerCase(),
        password: values.password,
        setErrors,
        setStatus,
        setLoading
      });
    }
  };

  useEffect(() => {
    if (router.query.reset?.length > 0 && errors.length === 0) {
      setStatus(atob(router.query.reset));
    } else {
      setStatus(null);
    }
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const showSignupModal = () => {
    setModalType("sigin");
    setShowModal(false);
    setModalType("signup");
    setShowModal(true);
  };
  const showForgotPasswordModal = () => {
    setModalType("sigin");
    setShowModal(false);
    setModalType("forgotPassword");
    setShowModal(true);
  };
  useEffect(() => {
    if (user) {
      setShowModal(false);
      setLimitExceed(false);
      setDocLimitExceed(false);
    }
    if (localStorage.checkbox && localStorage.email != "") {
      setValues({ email: localStorage.email, password: localStorage.password });
      setRememberMe(localStorage.checkbox == "true" ? true : false);
    }
  }, [user, localStorage]);
  return (
    <div className="w-full sm:w-full md:w-[360px] lg:w-[420px] xl:w-[450px] mx-auto">
      <div className="text-center">
        <Text as="h2" className="text-lg mt-4 mb-[32px] font-bold">
          {t.welcomeBack}
        </Text>
      </div>
      <div className="pb-[28px] flex-column items-center text-center justify-center sm:mr-0 lg:flex-row xl:flex xl:justify-between ">
        <GoogleButton className="mb-3 xl:mb-0 pr-[31px] xl:pr-[21px] py-[9px]" />
        <LinkedInButton className="text-base pr-[21px] py-[9px]" />
      </div>
      <div className="w-[280px] sm:w-[300px] md:w-[320px] lg:w-[320px] xl:w-[380px] relative text-center mx-auto z-10 mb-[23px]">
        <span className="absolute w-[280px] sm:w-[300px] md:w-[320px] lg:w-[320px] xl:w-[380px] border border-lightgrey border-t-1 border-b-0 top-3 left-0 -z-10"></span>
        <span className="p-4 bg-white z-40 text-lightgrey font-medium">
          {t.Or}
        </span>
      </div>
      {/* Session Status */}
      <AuthSessionStatus className="mb-4" status={status} t={t} />

      {/* Validation Errors */}
      <AuthValidationErrors className="mb-4" errors={errors} t={t} />
      <form onSubmit={submitForm}>
        <div className="mb-4">
          <Input
            placeholder={t.Email}
            type="email"
            className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${locale == "ar" ? "text-right rtl-input" : ""
              }`}
            value={values?.email}
            onChange={(event) => checkEmailVAlidity("email", event.target)}
          //onInvalid={(event) => validEmail("email", event.target)}
          />
          {feErrors.email && (
            <p
              className={`text-red text-sm ${locale == "ar" ? "text-right" : ""
                }`}
            >
              {t.emailRequired}
            </p>
          )}
        </div>
        <div className="relative">
          <span
            className={`absolute z-10 top-[11px] cursor-pointer ${locale == "ar" ? "left-4" : "right-2"
              }`}
            onClick={togglePassword}
          >
            {!passwordShown && <EyeOpen />}
            {passwordShown && <EyeClose />}
          </span>
          <Input
            placeholder={t.Password}
            type={passwordShown ? "text" : "password"}
            className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${locale == "ar" ? "text-right rtl-input" : ""
              }`}
            value={values.password}
            onChange={(event) => onChange("password", event.target.value)}
          />
          {feErrors.password && (
            <p
              className={`text-red text-sm ${locale == "ar" ? "text-right" : ""
                }`}
            >
              {t.passwordRequired}
            </p>
          )}
        </div>

        <div className="flex items-center justify-between mt-2">
          <label htmlFor="remember_me" className="inline-flex items-center">
            <input
              id="remember_me"
              type="checkbox"
              name="remember"
              className="rounded-[4px] border-gray-300 text-green shadow-sm focus:border-grey_300 focus:ring-0"
              defaultChecked={localStorage.checkbox == "true" ? true : false}
              onChange={(event) => setRememberMe(event.target.checked)}
            />

            <span className="ml-2 text-sm text-grey800 font-semibold">
              {t.RememberMe}
            </span>
          </label>
          <Button
            onClick={() => showForgotPasswordModal()}
            className="hover:bg-transparent p-0 active:ring-0 mb-2 text-sm text-grey800 font-semibold hover:text-gray-900"
            type="button"
          >
            {t.ForgotPassword}
          </Button>
        </div>

        <div className="flex mt-4 justify-center mx-auto mt-9 mb-8">
          <div className="flex-auto flex justify-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-green btn-block rounded-full"
            >
              {loading ? t.LoginLoading : t.Login}
            </button>
          </div>
        </div>
        <div className="flex items-center justify-center mt-2">
          <Button
            onClick={() => showSignupModal()}
            className="hover:bg-transparent p-0 active:ring-0 mb-2"
            type="button"
          >
            <a className="text-sm text-grey800 font-medium hover:text-gray-900">
              {t.doesNotHaveAccount}{" "}
              <span className="font-bold underline">{t.Signup}</span>
            </a>
          </Button>
        </div>
      </form>
    </div>
  );
}
