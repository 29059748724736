const AuthSessionStatus = ({ status, className, ...props }) => (
  <>
    {status && (
      <div className={`${className} font-medium text-sm text-green`} {...props}>
        {status}
      </div>
    )}
  </>
);

export default AuthSessionStatus;
